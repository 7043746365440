@import '../../assets/scss/common';

.Container {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  transition: opacity 200ms ease-out;
  opacity: 1;
  position: absolute;
  z-index: 99999;
  background-color: #FFF;
}

.Inner {
  font-weight: bold;
  font-size: 12rem;
  width: 12rem;
  height: 12rem;
  background: #000;
  color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: .25rem;
}

.Hide {
  opacity: 0;
}
