@import "../../../assets/scss/common";

.Container {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: $mobile-width) {
    flex-direction: row;
    gap: 0;
  }

  > div {
    width: 100%;
  }

  > div:not(:last-child) {
    margin-right: 1rem;
  }
}
