@import "../../../assets/scss/common";

.InputContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: $input-border-radius;
  border: $input-border;
  padding: $input-vertical-padding 0.75rem 0 0.75rem;
  font-weight: 400;
  line-height: $input-line-height;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: $content;

  &.Disabled {
    background: $input-disabled-bg;
  }
}

.SearchInput {
  border: 0;
  width: 5px;
  display: flex;
  flex-grow: 1;
  margin-bottom: $input-vertical-padding;

  &:focus {
    outline: none;
    border-color: $primary;
  }
}

.Selected {
  border-radius: 2px;
  padding: 0 $input-horizontal-padding;
  border: 1px solid darken($color: $dark-gray, $amount: 10%);
  background: $dark-gray;
  color: #fff;
  margin-right: $input-horizontal-padding;
  margin-bottom: $input-vertical-padding;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.SingleSelected {
    flex-grow: 1;
    margin-right: 0;
    flex-basis: 100%;
  }

  .Remove {
    font-weight: 700;
    font-size: 1.5rem;
    cursor: pointer;
    margin-left: $input-horizontal-padding;
    line-height: 0;
  }
}

@mixin option {
  width: 100%;
  height: 3rem;
  line-height: 3rem;
  padding: 0 1rem;
  border-bottom: 1px solid $dark-gray;
  white-space: nowrap;

  &:last-child {
    border-bottom: 0;
  }
}

.InfoOption {
  @include option;
}

.Option {
  @include option;
  cursor: pointer;

  &:hover {
    background: $gray-3;
  }

  &.OptionSelected {
    background: $primary;
    color: $white;
  }
}

.OptionsContainer {
  position: absolute;
  z-index: 1;
  width: 100%;
  background: $content;
  max-height: 15rem;
  overflow-y: auto;
  border: 1px solid $dark-gray;
}
