@import "../../../assets/scss/common";

.Container {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 1rem;
  border-radius: 4px;
}

@mixin alert-color($color, $text-color) {
  background: $color;
  border: 1px solid darken($color: $color, $amount: 10%);
  color: $text-color;
}

.Success {
  @include alert-color($success, $white);
}

.Danger {
  @include alert-color($danger, $white);
}

.Primary {
  @include alert-color($primary, $white);
}

.Warning {
  @include alert-color($warning, $text-color);
}

