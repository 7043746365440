.OptionContainer {
  display: flex;

  button {
    border-radius: 0;
    flex-grow: 1;
    flex-basis: 0;
  }

  button:first-child {
    border-radius: 4px 0 0 4px;
  }

  button:last-child {
    border-radius: 0 4px 4px 0;
  }
}
