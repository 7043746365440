@import "../../assets/scss/common";

.Background {
  background: $content;
}

.ErrorMessage {
  margin-top: 1rem;
}

.Container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  height: 800px;
}

.LoginBox {
  max-width: 450px;
  background: $white;
  padding: 1rem 3rem 3rem 3rem;
  margin: 0 1rem;
  border-radius: 0.25rem;
}

.Title {
  text-align: center;
  font-size: 30px;
  margin-bottom: 1rem;
}

.Inputs {
  margin-bottom: 2rem;
}

.ButtonContainer {
  text-align: center;
}

.Button {
  color: $white;
  border-radius: 0.25rem;
  border: 0;
  text-transform: uppercase;
}

.LinksContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;

  button {
    color: $white;
  }
}

.Border {
  border-right: 1px solid $border-color;
}

.LogoContainer {
  margin-bottom: 4rem;
  text-align: center;
}

.Logo {
  height: 100%;
  width: 100%;
}
