@import '../../../../assets/scss/common';


.InActive {
  background: $dark-gray;
}

.Invalid {
  border: 1px solid $danger;
}
