@import '../../../assets/scss/common';

$cell-padding: .5rem;

.Container {
  overflow-x: auto;
}

.Table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  
  th, td {
    padding: $cell-padding;
  }

  th {
    text-align: left;
    border-bottom: 2px solid $light-gray;
  }

  td {
    border-top: 1px solid $light-gray;
  }
}


.Hover {

  tbody > tr {
    cursor: pointer;
  }

  tbody > tr:hover {
    background: $table-hover; 
  }
}

.NoHover {
  cursor: default !important;
  
  &:hover {
    background: transparent !important;
  }
}