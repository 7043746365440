@import '../../../assets/scss/common';

.Container {
  padding: 1rem;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  h3 {
    margin: 0 0 .75rem 0;
  }

  ol, ul {
    margin: 0 0 0 1rem;
    padding: 0;

    li {
      margin: .5rem 0;
    }
  }

  ul {
    margin: 0;
    list-style: none;
  }
}