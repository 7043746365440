@import "../../../assets/scss/common";

$input-border: 1px solid $dark-gray;
$input-vertical-padding: 0.375rem;
$input-line-height: 1.5;
$input-border-radius: 4px;

.Container {
  margin-bottom: 0.75rem;
}

.Label {
  display: block;
  width: 100%;
  margin-bottom: 0.25rem;
}

.Invalid {
  .Label {
    color: $danger;
  }

  .Input {
    border-color: $danger;
  }
}

@mixin inputGroup {
  display: flex;
  align-items: center;
}

.InputGroup {
  &.HasPre {
    @include inputGroup;
    .Input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.HasPost {
    @include inputGroup;
    .Input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}

@mixin commonPrePost {
  padding: $input-vertical-padding 0.5rem;
  border-top: $input-border;
  border-bottom: $input-border;
  background-color: $light-gray;
  line-height: $input-line-height;
}

.Pre {
  @include commonPrePost;
  border-top-left-radius: $input-border-radius;
  border-bottom-left-radius: $input-border-radius;
  border-left: $input-border;
}

.Post {
  @include commonPrePost;
  border-top-right-radius: $input-border-radius;
  border-bottom-right-radius: $input-border-radius;
  border-right: $input-border;
}

@mixin Input {
  width: 100%;
  border-radius: $input-border-radius;
  border: $input-border;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: $input-line-height;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background: $content;

  &:focus {
    outline: none;
    border-color: $tertiary;
  }

  &:disabled {
    background: $input-disabled-bg;

    &::placeholder {
      color: $input-disabled-bg;
    }
  }
}

.Input {
  @include Input;
}

.Info {
  font-style: italic;
  margin: 0.25rem 0 0 0;
}

.InfoDanger {
  color: $danger;
}

.TopInfo {
  font-style: italic;
  margin: 0.25rem 0 0.25rem 0;
}
