@import './assets/scss/common';
@import '../node_modules/normalize.css/normalize.css';
@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');

* {
  box-sizing: border-box;
}

body {
  font-family: "Lato", Arial, Helvetica, sans-serif;
}

.text-danger {
  color: $danger;
}

.bold {
  font-weight: bold;
}


hr {
  border-color: $gray-3;
}