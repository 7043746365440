@import "../../assets/scss/common";

.root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.LogoContainer {
    padding: 12px 24px;
}

.Container {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    // background: $white-2;
}
