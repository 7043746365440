@import "../../../../assets/scss/common";

.Button {
  display: flex;
  justify-content: center;
  background: none;
  color: $gold;
  font-size: 2rem;
}

.InActive {
  color: $gray-1;
}

.Invalid {
  border: 1px solid $danger;
}
