.LinkIcon {
    font-size: 2rem;
    color: black;
    margin-top: 0.5rem;
}

.linkContainer {
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .LinkIcon {
        position: relative;
        top: 60%;
        left: 44%;
        font-size: 4rem;
        color: black;
        margin-top: 0rem;
    }
  }