@import "../../assets/scss/common";

.Container {
  padding: 0 1rem;
  background: $header;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.25);
}

@media print {
  .Container {
    display: none;
  }
}

.Wrapper {
  display: flex;
  align-items: center;
  height: 5rem;
}

.ToggleMenu {
  cursor: pointer;
  display: none;

  @include for-desktop-down {
    display: block;
  }
}

.Icon {
  color: $primary;
  font-size: 2rem;
}

.Logo {
  margin: 0 1rem 0 0;
  cursor: pointer;
}

.Title {
  color: $primary;
  text-transform: uppercase;
  margin: 0;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1000;
}
