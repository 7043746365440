@import '../../../assets/scss/common';

.TableHeader {
  th {
    cursor: pointer;
    word-break: break-all;
  }
}

.Icon {
  margin: 0 0 0 .5rem;
  font-size: .75rem;
  transition: transform 0.3s;
}

.HasAccessCheck {
  white-space: nowrap;
  text-align: center;

  svg {
    color: $success;
    font-size: 1.5rem;
  }
}