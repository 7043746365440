.Backdrop {
  width: 0;
  height: 0;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity linear 0.3s;
}

.Active {
  opacity: 1;
  width: 100%;
  height: 100%;
}
