.TableHeader {
  th {
    cursor: pointer;
  }
}

.Icon {
  margin: 0 0 0 .5rem;
  font-size: .75rem;
  transition: transform 0.3s;
}
