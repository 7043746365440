@import "../../../assets/scss/common";

.Dropzone {
  border: 1px solid $border-color;
  border-radius: $border-radius;
}

.Container {
  position: relative;
  padding: 0.375rem 0.75rem;
  display: flex;
  align-items: center;
}

.Text {
  font-size: $font-size-sm;
  color: $text-color;
  margin-bottom: 0;
}
