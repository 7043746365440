.ImageContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
}

.Image {
  max-width: 200px;
  max-height: 200px;
}

.Label {
  display: block;
  width: 100%;
  margin-bottom: 0.25rem;
}

.StaticContent {
  label{
    margin-top: .25rem;
  }

  p {
    margin-top: .25rem;
  }
}
