@import '../../../assets/scss/common';

.HelpText {
  margin: 1rem 0;
  p {
    margin: 0.25rem 0;
  }
}

.Error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem 0;
}

.ErrorIcon {
  font-size: 4rem;
  color: $danger;
}