@import "../../../assets/scss/common";

.Container {
  text-align: center;
  width: 100%;
  margin: 0;
}

.Open {
  color: $text-color;
  font-weight: normal;

  & > div {
    width: 100%;
  }
}
